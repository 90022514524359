<template>
  <!-- Contact form -->
  <div class="col-md-12" style="margin-top: 3%; margin-bottom: 3%">
    <div class="card card-container">
      <div class="card-header" style="background-color: #212529; color: white">
        <h5><i class="fa-regular fa-envelope"></i> Kontakt oss</h5>
      </div>
      <div class="card-body" style="background-color: #212529; color: white">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <!-- Fist name -->
            <b-form-group
              id="input-group-1"
              label="Fornavn"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.fornavn"
                type="text"
                placeholder="..."
                style="margin-top: 5px"
                required
              ></b-form-input>
            </b-form-group>

            <!-- Last name -->
            <b-form-group
              id="input-group-2"
              label="Etternavn"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.etternavn"
                type="text"
                placeholder="..."
                style="margin-top: 5px"
                required
              ></b-form-input>
            </b-form-group>

            <!-- Email address -->
            <b-form-group id="input-group-3" label="E-post" label-for="input-3">
              <b-form-input
                id="input-3"
                v-model="form.epost"
                type="email"
                placeholder="din@epost.com"
                style="margin-top: 5px"
                required
              ></b-form-input>
            </b-form-group>

            <!--Message -->
            <b-form-group
              id="input-group-4"
              label="Din melding"
              label-for="input-4"
            >
              <b-form-textarea
                id="input-4"
                v-model="form.melding"
                placeholder="skriv her..."
                rows="3"
                style="margin-top: 5px"
                required
              ></b-form-textarea>
            </b-form-group>
            <br />

            <!-- Send button -->
            <b-button
              type="submit"
              variant="success"
              style="width: 66%; margin-top: 2%; margin-bottom: 2%"
              :pressed="false"
            >
              Send
            </b-button>
            <!-- Reset button -->
            <b-button
              type="reset"
              variant="outline-light"
              style="
                width: 31%;
                margin-left: 3%;
                margin-top: 2%;
                margin-bottom: 2%;
              "
              :pressed="false"
            >
              Nullstill
            </b-button>
          <small>Dette nettstedet er beskyttet av reCAPTCHA og Googles <a href="https://policies.google.com/privacy">personvernregler</a> og <a href="https://policies.google.com/terms">vilkår</a> for bruk gjelder.<br/>
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</small>
          </b-form>
        </div>
      </div>
    </div>
  </div>

  <transition name="fade" mode="out-in">
    <div v-if="activateAlert && message" class="alert" role="alert">
      <b-alert variant="warning" show>{{ message }}</b-alert>
    </div>
  </transition>
</template>

<script>
import UserDataService from "../../services/user.service";

export default {
  data() {
    return {
      activateAlert: false,
      form: {
        fornavn: "",
        etternavn: "",
        epost: "",
        melding: "",
      },
      show: true,
    };
  },
  watch: {
    activateAlert(val) {
      if (val) {
        setTimeout(() => (this.activateAlert = false), 3500);
      }
    },
  },

  methods: {

    async onSubmit(event) {
      event.preventDefault();
      await this.$recaptchaLoaded();
      // Get recaptcha token logged with aciton contactForm 
      const rcToken = await this.$recaptcha("contactForm");

      UserDataService.sendContactForm(this.form, rcToken)
        .then((response) => {
          this.response = response.data;
          console.log(response.data);
          this.alert = "Kontaktskjema er sendt inn!";
          this.message = this.response.message;
          this.activateAlert = true;
        })
        .catch((error) => {
          console.log(error.response);
          this.alert = error.response.data.message;
          this.activateAlert = true;
        });
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.fornavn = "";
      this.form.etternavn = "";
      this.form.epost = "";
      this.form.melding = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style scoped>
.card-container.card {
  max-width: 500px !important;
}

.card {
  background-color: #212529;
  padding: 16px;
  margin: 0 auto;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

/* Input focus white glow */
.form-control:focus {
  border-color: white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px white;
}
</style>
